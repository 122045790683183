import Navlink from "@/component/Navlink";
//import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import SVG from "react-inlinesvg";

import styles from "./desktopNavbar.module.scss";
import textLanguage from "@/constants/textLanguage";
import siteInfo from "@/constants/siteInfo";
import sendGAEvent from "@/utils/gaEvent";

const Navbar = () => {
  const [searchText, setSearchText] = useState("");
  const router = useRouter();
  const isRajasthan = router?.asPath?.includes("/rajasthan");
  const isMadhya = router?.asPath?.includes("/madhya-pradesh");
  const isHR = router?.asPath?.includes("/haryana");
  const isBH = router?.asPath?.includes("/bihar");
  const isUP = router?.asPath?.includes("/uttar-pradesh");
  const isDL = router?.asPath?.includes("/delhi");
  const isCGT = router?.asPath?.includes("/chhattisgarh");

  const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;

  const handleSearch = () => {
    if (!searchText?.trim()?.length > 0) return;
    setSearchText("");
    let eventName = `view_search_results`;
    sendGAEvent({
      eventName: eventName,
      pageTitle: document?.title,
      pageLocation: window?.location?.href,
      searchQuery: searchText?.trim(),
    });
    window.location.href = `${
      process.env.NEXT_PUBLIC_WEBSITE_URL
    }/search/${encodeURIComponent(searchText?.trim())}`;
    // setSearchText("");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchText?.trim()?.length > 0) {
      handleSearch();
    }
  };
  return (
    <nav
      className={`top-0 bg-white shadow-sm lg:pl-4 md:pl-4 sm:pl-2 min-h-[4rem] relative`}
    >
      <div className="w-full flex justify-between items-center py-2 h-16">
        <div
          className="shrink-0 min-w-[100px] md:w-[14.67%]"
          style={{ width: "auto" }}
        >
          <a
            href={
              websiteUrl +
              (isMadhya
                ? "/madhya-pradesh"
                : isRajasthan
                ? "/rajasthan"
                : isHR
                ? "/haryana"
                : isBH
                ? "/bihar"
                : isUP
                ? "/uttar-pradesh"
                : isDL
                ? "/delhi-news"
                : isCGT
                ? "/chhattisgarh"
                : "/")
            }
            onClick={() => {
              let eventName = `TopBar-LogoClick`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <img
              src={
                isRajasthan
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-2994.png"
                  : isMadhya
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-3068.png"
                  : isHR
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/haryana_tak_light.png"
                  : isBH
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/bihar_tak_light.png"
                  : isUP
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/uptak_light.png"
                  : isDL
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/delhi_tak_light.png"
                  : isCGT
                  ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/ccg_logo.png"
                  : siteInfo?.siteLogo
              }
              title={
                isMadhya
                  ? "MP Tak"
                  : isRajasthan
                  ? "Rajasthan Tak"
                  : isUP
                  ? "UP Tak"
                  : isHR
                  ? "Haryana Tak"
                  : isBH
                  ? "Bihar Tak"
                  : isDL
                  ? "Delhi Tak"
                  : isCGT
                  ? "Chhattisgarh Tak"
                  : siteInfo?.siteName
              }
              alt={
                isMadhya
                  ? "MP Tak"
                  : isRajasthan
                  ? "Rajasthan Tak"
                  : isUP
                  ? "UP Tak"
                  : isHR
                  ? "Haryana Tak"
                  : isBH
                  ? "Bihar Tak"
                  : isDL
                  ? "Delhi Tak"
                  : isCGT
                  ? "Chhattisgarh Tak"
                  : siteInfo?.siteName
              }
              width="92px"
              loading="eager"
              className={`${
                isMadhya
                  ? "w-[90px]"
                  : isRajasthan
                  ? "w-[130px]"
                  : isHR
                  ? "w-[120px]"
                  : isBH
                  ? "w-[100px]"
                  : isDL
                  ? "w-[120px]"
                  : isUP
                  ? "w-[90px]"
                  : isCGT
                  ? "w-[120px]"
                  : "w-[110px]"
              }`}
            />
          </a>
        </div>

        {/* Desktop menu */}
        <div
          className={`right-0 bg-white w-full md:static md:flex ms-5 divide-x divide-slate-300 md:w-[63%]`}
        >
          <Navlink
            exact={true}
            className={`flex items-center px-4  ${
              router?.asPath === "/" ? "text-primary" : ""
            } ${
              isRajasthan
                ? "hover:text-rajasthanDark"
                : isMadhya
                ? "hover:text-mpDark"
                : isHR
                ? "hover:text-hrDark"
                : isBH
                ? "hover:text-biharDark"
                : isUP
                ? "hover:text-upDark"
                : isCGT
                ? "hover:text-mpDark"
                : "hover:text-primary"
            } min-w-[100px]`}
            href={websiteUrl}
            title={textLanguage?.headerStaticMenuText?.home}
            onClick={() => {
              let eventName = `TopBar-Home`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <SVG
              src="/assets/icons/svg/home.svg"
              className="h-6 w-6 mr-2"
              title="home"
            />

            {textLanguage?.headerStaticMenuText?.home}
          </Navlink>
          <Navlink
            className={`flex items-center px-4  ${
              router?.asPath?.includes("-livetv") ? "text-primary" : ""
            } ${
              isRajasthan
                ? "hover:text-rajasthanDark"
                : isMadhya
                ? "hover:text-mpDark"
                : isHR
                ? "hover:text-hrDark"
                : isBH
                ? "hover:text-biharDark"
                : isUP
                ? "hover:text-upDark"
                : isCGT
                ? "hover:text-mpDark"
                : "hover:text-primary"
            } min-w-[100px]`}
            // className="flex items-center px-4 hover:text-primary min-w-[100px]"
            // href={process.env.NEXT_PUBLIC_VISUALSTORY_URL}
            // role="button"
            title={textLanguage?.headerStaticMenuText?.liveTV}
            href={websiteUrl + "/aajtak-livetv"}
            exact={true}
            onClick={() => {
              let eventName = `TopBar-ReadClick`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/livetv.svg"
                className="h-6 w-6 mr-2"
                title="livetv"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.liveTV}
          </Navlink>
          <Navlink
            className={`flex items-center px-4
               ${router?.asPath?.includes("/stories") ? "text-primary" : ""}
               ${
                 isRajasthan
                   ? "hover:text-rajasthanDark"
                   : isMadhya
                   ? "hover:text-mpDark"
                   : isHR
                   ? "hover:text-hrDark"
                   : isBH
                   ? "hover:text-biharDark"
                   : isUP
                   ? "hover:text-upDark"
                   : isCGT
                   ? "hover:text-mpDark"
                   : "hover:text-primary"
               } min-w-[100px]`}
            // className="flex items-center px-4 hover:text-primary min-w-[100px]"
            // href={process.env.NEXT_PUBLIC_VISUALSTORY_URL}
            // role="button"
            title={textLanguage?.headerStaticMenuText?.read}
            href={websiteUrl + "/stories"}
            exact={true}
            onClick={() => {
              let eventName = `TopBar-ReadClick`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/read.svg"
                className="h-6 w-6 mr-2"
                title="webstories"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.read}
          </Navlink>

          <Navlink
            className={`flex items-center px-4  ${
              router?.asPath?.includes("/videos") ? "text-primary" : ""
            }  ${
              isRajasthan
                ? "hover:text-rajasthanDark"
                : isMadhya
                ? "hover:text-mpDark"
                : isHR
                ? "hover:text-hrDark"
                : isBH
                ? "hover:text-biharDark"
                : isUP
                ? "hover:text-upDark"
                : isCGT
                ? "hover:text-mpDark"
                : "hover:text-primary"
            } min-w-[102px]`}
            href={websiteUrl + "/videos"}
            title={textLanguage?.headerStaticMenuText?.watch}
            onClick={() => {
              let eventName = `TopBar-WatchClick`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/video.svg"
                className="h-6 w-6 mr-2"
                title="videos"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.watch}
          </Navlink>
          <Navlink
            className={`flex items-center px-4
              ${
                router?.asPath?.includes("/visualstories") ? "text-primary" : ""
              } ${
              isRajasthan
                ? "hover:text-rajasthanDark"
                : isMadhya
                ? "hover:text-mpDark"
                : isHR
                ? "hover:text-hrDark"
                : isBH
                ? "hover:text-biharDark"
                : isUP
                ? "hover:text-upDark"
                : isCGT
                ? "hover:text-mpDark"
                : "hover:text-primary"
            } min-w-[126px]`}
            // href={process.env.NEXT_PUBLIC_VISUALSTORY_URL}
            role="button"
            href={websiteUrl + "/visualstories"}
            title={textLanguage?.headerStaticMenuText?.webstories}
            onClick={() => {
              let eventName = `TopBar-WebstoryClick`;
              sendGAEvent({
                eventName: eventName,
                pageTitle: document?.title,
                pageLocation: window?.location?.href,
              });
            }}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/video-clip.svg"
                className="h-6 w-6 mr-2"
                title="webstories"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.webstories}
          </Navlink>

          {/* 
          <Navlink
            className="flex items-center px-4 hover:text-primary min-w-[96px]"
            href="/business"
            title={textLanguage?.headerStaticMenuText?.business}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/world.svg"
                className="h-6 w-6 mr-2 "
                title="shorts"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.business}
          </Navlink>

          <Navlink
            className="flex items-center px-4 hover:text-primary min-w-[96px]"
            href="/short-videos"
            title={textLanguage?.headerStaticMenuText?.shorts}
          >
            <div className="min-w-[25px]">
              <SVG
                src="/assets/icons/svg/video-clip.svg"
                className="h-6 w-6 mr-2"
                title="shorts"
              />
            </div>
            {textLanguage?.headerStaticMenuText?.shorts}
          </Navlink> */}
        </div>
        {(isRajasthan || isMadhya || isHR || isBH || isUP || isDL || isCGT) && (
          <a
            className={`text-black flex items-center px-4 w-[20%] font-semibold min-w-[126px]`}
            // href={process.env.NEXT_PUBLIC_VISUALSTORY_URL}
            role="button"
            href={websiteUrl}
            title="Back to NewsTak"
          >
            <SVG src="/assets/icons/svg/left.svg" />
            <span className="mx-1 whitespace-nowrap">Back To </span>
            <div className="min-w-[25px]">
              <img
                src={siteInfo.siteLogo}
                className="h-6 w-18 mr-2"
                title="Back to NewsTak"
                alt="Back to NewsTak"
                loading="eager"
              />
            </div>
          </a>
        )}
        <div
          className={`absolute top-0 right-0 bg-white z-50 w-full md:static md:flex md:items-center md:w-[25%]`}
        >
          {/* Search Bar */}
          <div className="flex items-center mx-auto w-[90%]">
            <div className="relative w-full">
              <input
                id="search"
                className={`${styles.searchInput} placeholder:italic placeholder:text-slate-400 block w-full bg-white border border-slate-300 rounded-md py-2 pl-3 pr-10 shadow-sm focus:outline-none focus:border-primary-300 focus:ring-primary-300 focus:ring-1 sm:text-sm`}
                placeholder="यहां खोज करें"
                type="text"
                name="search"
                value={searchText}
                onKeyDown={handleKeyPress}
                minLength={1}
                onChange={(e) => setSearchText(e?.target?.value)}
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => handleSearch()}
              >
                <img
                  className="text-gray-500"
                  loading="eager"
                  src="/assets/icons/svg/search.svg"
                  alt="Search Icon"
                  height={20}
                  width={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
