export default {
  headerStaticMenuText: {
    home: "होम",
    video: "वीडियो",
    webstories: "विज़ुअल स्टोरीज़",
    politics: "राजनीति",
    shorts: "शॉर्ट्स",
    business:"बिजनेस",
    read:"पढ़िए",
    watch:"देखिए",
    liveTV:"लाइव टीवी",
  },
  footerStaticMenuText: {
    aboutUs: "हमारे बारे में",
    contact: "संपर्क करें",
    terms: "टर्म्स ऑफ यूज",
    privacy: "प्राइवेसी पालिसी",
  },
  relatedNewsText: "यह भी पढ़ें...",
  relatedVideosText: "यह भी देखे...",
  liveEnded: "लाइव-ब्लॉग समाप्त हुया।",
  breakingNews: "ब्रेकिंग न्यूज़",
  liveBlog: "लाइव",
};
